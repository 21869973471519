<template>
    <common-form
            ref="form"
            url="web/machine/classify"
            :title="title"
            :items="items"
            :edit="edit"
            :before-request="beforeRequest"
            @success="$emit('success')">
    </common-form>
</template>
<script>

    export default {
        data() {
            return {
                edit:false,
                id:null,
                parentId: null,
            }
        },
        computed: {
            title(){
                return this.edit?"编辑分类":(this.parentId?"添加小分类":"添加大分类")
            },
            items() {
                return [
                    {
                        type: "input",
                        name: "名称",
                        key: "name",
                        required: true,
                    },
                ]
            }
        },
        methods: {
            show(model = {}) {
                this.edit = model.id > 0
                this.id = model.id
                this.parentId = model.parentId
                this.$refs.form.show(model)
            },
            beforeRequest(model){

                return {
                    ...model,
                    id:this.id,
                    parentId:this.parentId
                }
            }
        }
    }
</script>
